<template>
  <f7-page @page:init="pageInit">
    <f7-navbar
      title="民易贷"
      back-link="  "
      sliding
    >
    </f7-navbar>


    <div class="bg">
      <!-- 左上角logo -->
      <div>
        <p>
          <img class="bank-icon-image" src="../assets/bank/ms/text2.png" />
        </p>
      </div>

      <!-- 民生民意贷 -->
      <div class="loan-box">
        <div>
          <p>
            <img style="width: 60%;" src="../assets/bank/ms/text1.png" />
          </p>
        </div>
        <div>
          <p>
            <img style="width: 75%;" src="../assets/bank/ms/text3.png" />
          </p>
        </div>
      </div>

      <!-- “100”（冒金光） -->
      <div class="number-100">
        <p>
          <img style="width: 50%;" src="../assets/bank/ms/text100.png" />
        </p>
      </div>

      <!-- 五大特征简介 -->
      <!-- <div class="short-intro--box">

        <f7-row no-gap>
          <f7-col>
            <p class="bottom-1">
              线上or线下/纯信用/秒放款
            </p>
            <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >额度期限最长3年；线上申请，额度最高20万元；线下申请，额度最高30万元</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >预约上门办理或扫码办理、快速审批</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >优惠利率一口价、消贷红包、利息优惠券、好礼享不停</f7-col>
              </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col>
            <p class="bottom-1">
              限时优惠政策
            </p>
            <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >同一单位组团办理，齐享优惠定价：5-19人，4.9%；20人及以上，4.75%（均为年化率，单利）</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >7-8月当月民易贷签约并提款，享120元消贷红包，可直接抵扣贷款利息。消贷红包于签约次月15日前导入客户账户</f7-col>
              </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col>
            <p class="bottom-1">
              定额贷：额度无忧
            </p>
            <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >同一单位组团办理，齐享优惠定价：5-19人，4.9%；20人及以上，4.75%（均为年化率，单利）</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >7-8月当月民易贷签约并提款，享120元消贷红包，可直接抵扣贷款利息。消贷红包于签约次月15日前导入客户账户</f7-col>
              </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col>
            <p class="bottom-1">
              截屏贷：服务无忧
            </p>
            <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >适用所在单位暂未认定为优质单位的员工，最快T+1出具审批结果</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >单人申请：身份证；收入证明/工资流水/缴金纳税证明之一</f7-col>
              </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col>
            <p class="bottom-1">
              特邀二维码：速度无忧
            </p>
            <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >扫描二维码，准确填写个人信息，即可出具审批结果</f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                <f7-col width="90" >单人申请：我行借记卡或直接按照页面指引开立电子银行二类户</f7-col>
              </f7-row>
          </f7-col>
        </f7-row>
      </div> -->

      <!-- 温馨提示 -->
      <!-- <div>
        <f7-block-title style="">温馨提示：</f7-block-title>
        <f7-block-header>1、活动时间为2021年7月16日至2022年8月31日，具体额度以中国民生银行审批为准</f7-block-header>
        <f7-block-header>2、个人消费贷款资金不得流入股市、以及其他限制性领域</f7-block-header>
        <f7-block-header>3、在法律允许的范围内，本活动的解释权归中国民生银行股份有限公司上海分行所有</f7-block-header>
      </div> -->

      <!-- 民生银行“民易贷” -->
      <!-- <div>
        <p class="detail-title">民生银行“民易贷”</p>
        <p class="sep-line"></p>
      </div> -->

      <!-- <div class="product-feature">
        <p class="bottom-info-text">申请方式</p>
      </div> -->

      <!-- 额度申请 -->
      <!-- <div class="product-feature">
        <p class="bottom-1">
          额度申请 
        </p>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95" >点击底部立即申请</f7-col>
            </f7-row>
          </f7-col>
          
        </f7-row>
      </div> -->

      <!-- 适用人群 -->
      <div class="product-feature">
        <p class="bottom-1">
          适用人群
        </p>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="50">
                <f7-row no-gap style="padding-top: 5px;">
                  <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                  <f7-col width="90" >民生代发工资客户</f7-col>
                </f7-row>
              </f7-col>
              <f7-col width="50">
                <f7-row no-gap style="padding-top: 5px;">
                  <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                  <f7-col width="90" >民生贵宾客户</f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="50">
                <f7-row no-gap style="padding-top: 5px;">
                  <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                  <f7-col width="90" >民生按揭客户</f7-col>
                </f7-row>
              </f7-col>
              <f7-col width="50">
                <f7-row no-gap style="padding-top: 5px;">
                  <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                  <f7-col width="90" >他行按揭客户</f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="50">
                <f7-row no-gap style="padding-top: 5px;">
                  <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                  <f7-col width="90" >公积金/个税缴纳客户</f7-col>
                </f7-row>
              </f7-col>
              <f7-col width="50">
                <f7-row no-gap style="padding-top: 5px;">
                  <f7-col width="10" style="color:#C5AA8A;text-align: center;">♦</f7-col>
                  <f7-col width="90" >优质单位客户</f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    存量贷款客户的续授信服务
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
      </div>

      <!-- 申请流程:  -->
      <div class="product-feature">
        <p class="bottom-1">
          申请流程
        </p>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    额度申请
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        
        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95" >
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5"></f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    点击底部立即申请
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    提款流程
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        
        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95" >
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5"></f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    登录手机银行、个人网上银行
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95" >
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5"></f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    选择【贷款】<span style="color: #EFA33F">▸▸</span>【民生民易贷】<span style="color: #EFA33F">▸▸</span>【即可申请】
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <!-- <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="10"></f7-col>
          <f7-col width="90">登录手机银行、个人网上银行</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="10"></f7-col>
          <f7-col width="90">选择【贷款】<span style="color: #EFA33F">▸▸</span>【民生民易贷】<span style="color: #EFA33F">▸▸</span>【即可申请】</f7-col>
        </f7-row> -->
      </div>

      <div class="product-feature">
        <p class="bottom-1">
          注意事项
        </p>
        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    此优惠利率仅针对东航员工，如非东航员工，将无法审批通过，具体审批结果以银行为准。
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    审批额度不需开卡，但提款前需完成开卡操作。
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    完成额度申请后，民生银行民易贷专员会及时与您联系。
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    在单位名称填写的时候，应填写完整的工作单位名称。
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row no-gap>
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap style="padding-top: 5px;">
              <f7-col width="5" style="color:#C5AA8A;text-align: center;">♦</f7-col>
              <f7-col width="95">
                <f7-row no-gap>
                  <f7-col>
                    如有疑问，可联系客户经理：宋经理，联系电话：13611964307。
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

      </div>
      



      <!-- 注意事项 -->
      <!-- <div class="product-feature">
        <f7-menu>
          <f7-chip text="注意事项" color="red"></f7-chip>
        </f7-menu>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;">♦</f7-col>
          <f7-col width="95" >此优惠利率仅针对<span class="text-part-red">东航员工</span>，如非东航员工，将无法审批通过，具体审批结果以银行为准。</f7-col>
        </f7-row>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;">♦</f7-col>
          <f7-col width="95" >审批额度不需开卡，但<span class="text-part-red">提款前需完成开卡操作</span>。</f7-col>
        </f7-row>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;">♦</f7-col>
          <f7-col width="95" >完成额度申请后，<span class="text-part-red">民生银行民易贷专员会及时与您联系</span>。</f7-col>
        </f7-row>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;">♦</f7-col>
          <f7-col width="95" >在单位名称填写的时候，应填写<span class="text-part-red">完整的工作单位名称</span>。</f7-col>
        </f7-row>
      </div> -->

      <!-- 联系 -->
      <!-- <div class="product-feature">
        <f7-menu>
          <f7-chip text="各类融资需求请与我联系" color="red"></f7-chip>
        </f7-menu>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;"></f7-col>
          <f7-col width="95" >客户经理：宋经理      </f7-col>
        </f7-row>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;"></f7-col>
          <f7-col width="95" >手机号：13611964307</f7-col>
        </f7-row>
        <f7-row no-gap>
          <f7-col width="5" style="text-align: right;"></f7-col>
          <f7-col width="95" >地址：民生银行长宁支行，长宁区定西路1115号</f7-col>
        </f7-row>
      </div> -->
    </div>
    <div class="bg-bottom">
    </div>

    <!-- 底部按钮 -->
    <div class="buttonSublit btnAnimation">
      <a onclick="_hmt.push(['_trackEvent', 'msBank_external_link_apply_loan', 'click'])">
      <f7-button fill round large href='https://t.cmbc.com.cn/BRjuF' external>
        立即申请
      </f7-button>
      </a>
    </div>

  </f7-page>
</template>

<script>
// import { getDevice } from "../f7-config/f7-custom";
export default {
  data() {
    return {
    }
  },

  methods: {
    pageInit () {

      // const device = getDevice();
      // this.isDesktop = device.desktop;
      // this.smallScreen = screen.width < 400 ? true : false
    },
    // apply () {
    //   window.open('https://t.cmbc.com.cn/USwSo',"_blank")
    // }
  }
}
</script>


<style lang="less" scoped>

.bg {
  background: white;
  left:0%;
  right:0%;
  top:0%;
  padding: 15px;
  background: url("../assets/bank/ms/msbg2-part1.jpg") no-repeat center;
  background-size: 100%100%;
}
.bg-bottom {
  left:0%;
  right:0%;
  width: 100%;
  height: 100px;
  bottom: 0%;
  background: url("../assets/bank/ms/msbg2-part2.jpg") no-repeat center;
  background-size: 100%100%;
}

.bank-icon-image {
  z-index: 1;
  width: 40%;
  left: 9%;
}

.loan-box {
  text-align: center;
  background: url("../assets/bank/ms/flash.png") no-repeat center;
  background-size: 87%100%;
}

.bank-title-image {
  width: 90%;
  display: block;
  position: absolute;
  left: 5%;
  right: 5%;
  text-align: center;
  background: url("../assets/bank/ms/flash.png") no-repeat center;
  background-size: 90%100%;
}

.number-100 {
  text-align: center;
  background: url("../assets/bank/ms/boom.png") no-repeat center;
  background-size: 100%100%;
}

.short-intro--box {
  color: white;
  position: relative;
}

.bottom-1 {
  font-size: 15px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  background: url("../assets/bank/ms/full.png") no-repeat center;
  background-size: 60%100%;
  font-style: italic;
  color: white;
  font-weight: bold;
}

.block-header {
  color: white;
  padding-left: 10%;
  margin-bottom: 0;
  --f7-block-header-font-size: 10px;
  --f7-block-margin-vertical: 0;
}
.block-title {
  margin-bottom: 0;
  margin-top: 5%;
  color: white;
  left: 5%;
  width: 90%;
  --f7-block-title-font-size: 10px;
}

.product-feature {
  color: white;
  padding-top: 10px;
  padding-left: 7%;
  padding-right: 7%;
  font-size: 12px;
  >p {
    margin: 0;
  }
}

 .bottom-info-text {
   font-size: 20px;
   color: white;
   font-weight: bold;
   text-align: center;
 }
 .button-fill {
  background: -prefix-linear-gradient(left, rgb(200,25,46), #FFB6C1); 
  background: linear-gradient(to right, rgb(200,25,46), #FFB6C1);
 }
 .button-large {
  --f7-button-large-font-size: 25px;
}
</style>